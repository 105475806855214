import '../../styles/LayoutDesign.scss';

import * as React from 'react';

import { Row, Col } from 'react-bootstrap';

import {
  LayoutDesignContainerProps,
  LayoutDesignContainerState,
} from '../../@types/LayoutDesign.d';

export default class LayoutDesignContainer extends React.Component<
  LayoutDesignContainerProps,
  LayoutDesignContainerState
> {
  constructor(props: LayoutDesignContainerProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    return (
      <Row className="no-gutters h-100 w-100 align-items-center">
        <Col className="text-center">Layoutgestaltung</Col>
      </Row>
    );
  }
}
