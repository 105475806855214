// eslint-disable-next-line import/no-cycle
import { Moment } from 'moment';

// eslint-disable-next-line import/no-cycle
import { Area } from './Area.d';

export interface User {
  id: number;
  email: string;
  prename: string;
  lastname: string;
  salutation: Salutation;

  avatar: string;
}

export interface Client {
  id: numer;
  name: string;
  transmissionType: TransmissionType;
  weekparts: Weekpart[];
  products?: Prodcut[];
  clientLocations?: ClientLocation[];
  showPrice: boolean;
  additionalOptions?: AdditionalOptionSettings[];
  distributionDateType: DistributionDateType;
  distributionAppointments: DistributionAppointment[];

  billingType?: BillingType;
  billingAddress?: BillingAddress;
}

export interface Product {
  id: number;
  name: string;
  printDocRequired: boolean;
  productPrices: ProductPrice[];
}

export interface ClientLocation {
  addressName: string;
  city: string;
  colorSelectedFill: string;
  housenumber: string;
  id: number;
  name: string;
  number: string;
  postcode: string;
  street: string;

  show: boolean;
  selected: boolean;

  areas: Area[];
  price?: LocationPrice;
  billingDefault: boolean;
}

export interface LocalitySendFormat {
  id: number;
  localityKey: string;
  selected: boolean;
}

export interface AreaSendFormat {
  areaKey: string;
  countryCode: string;
  type: string;
  localities: LocalitySendFormat[];
}

export interface SubsidiarySendFormat {
  id: number;
  areas: AreaSendFormat[];
  masterFile?: string;
  extraCopies?: number;
}

export interface BillingAddress {
  postcode?: string;
  city?: string;
  street?: string;
  housenumber?: string;
  name?: string;
}

export interface RequestPayload {
  clientId?: number;
  userId: number;
  actionName?: string;
  locations: SubsidiarySendFormat[];
  company?: string;
  email: string;
  forname: string;
  message?: string;
  phone?: string;
  productId: number;
  salutation: string;
  surename: string;
  billingSubsidiary?: ClientLocation;
  billingType: BillingType;
  distributionDay?: string;
  weekpart: Weekpart;
  additionalOptions: AdditionalOptions;
  dateType: DateType;
  masterFile: string;
}

export interface AdditionalOptions {
  kaufDaItems?: KaufDaItem[];
  facebookItems?: SocialMediaItem[];
  instagramItems?: SocialMediaItem[];
  tiktokItems?: SocialMediaItem[];
}

export interface Price {
  id?: number;
  total: string;
  totalInclusiveVat: string;
  vat: string;
  totalPerThousand: string;
}

export interface LocationPrice extends Price {
  circulation: number;
  totalKaufDa: string;
  totalPerThousand: string;
}

export interface TotalPrice extends Price {
  subtotalKaufDa: string;
  subtotalDistribution: string;
  subtotalDistributionPerThousand: string;
  subtotalPrint: string;
  subtotalPrintPerThousand: string;
}

export interface ProductPrice {
  id: number;
  price: Price;
  quantityFrom: number;
  quantityTo: number;
}

export interface PriceResult {
  price: TotalPrice;
  subsidiaryPrices?: LocationPrice[];
}

export interface AdditionalOptionSettings {
  id: number;
  type: AdditionalOptionType;
  enabled: boolean;
  mode: AdditionalOptionsMode;
  price: number;
}

export interface KaufDaItemSettings extends AdditionalOptionSettings {
  defaultRange?: number;
  defaultDuration?: number;
  defaultHomepage?: string;
  presetSelectionRanges: number[];
  presetSelectionDurations: KaufDaDurationPrice[];
  presetSelectionHomepages: string[];
}

export interface SocialMediaItemSettings extends AdditionalOptionSettings {
  platform: SocialMedia;
  defaultRange?: number;
  defaultDuration?: number;
  defaultBudget?: number;
  defaultTargetGroups: SocialMediaTargetGroup[];
  presetSelectionRanges: number[];
  presetSelectionDurations: number[];
  presetSelectionBudgets: number[];
  presetSelectionTargetGroups: SocialMediaTargetGroup[];
}

export interface AdditionalOption {
  type: AdditionalOptionType;
  selected: boolean;
  subsidiaryId: number;
  subsidiaryName: string;
}

export interface KaufDaItem extends AdditionalOption {
  range?: number;
  duration?: KaufDaDurationPrice;
  alternateHomepage?: string;
  startDate: Moment;
}

export interface KaufDaDurationPrice {
  id: number;
  duration: number;
  price: string;
}

export interface SocialMediaItem extends AdditionalOption {
  platform: SocialMedia;
  range?: number;
  duration?: number;
  budget?: number;
  targetGroups: SocialMediaTargetGroup[];
  startDate: Moment;
}

export interface SocialMediaTargetGroup {
  id: number;
  name: string;
}

export interface DistributionAppointment {
  id: number;
  date: Moment;
  type: DateType;
  name: string;
}

export interface MasterFile {
  subsidiaryId: number;
  masterFile: File;
}

export interface ExtraCopy {
  subsidiaryId: number;
  extraCopies: number;
}

export enum SocialMedia {
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
}

export type AdditionalOptionType = 'SOCIALMEDIA' | 'KAUFDA';
export type Weekpart = 'WEEKEND' | 'MIDWEEK' | 'BEST' | '';
export type DistributionType = 'DIRECT' | 'INSERT';
export type DistributionDateType = 'FREE' | 'TEMPLATE';
export type DateType = 'DAY' | 'WEEK';
export type Salutation = 'Herr' | 'Frau';
export type TransmissionType = 'OFFER' | 'ORDER';
export type BillingType = 'CLIENT' | 'LOCATION' | 'PER_LOCATION';
export type AdditionalOptionsMode = 'DEFAULT' | 'PRESET';
export type WarningMessageType = 'info' | 'warn' | 'error' | 'success';
