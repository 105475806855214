/* eslint-disable no-nested-ternary */
import * as React from 'react';

import { Form, Col, Row } from 'react-bootstrap';
import Select from 'react-select';

import ContentContainer from './ContentContainer';

import {
  OFFER_MODAL_PRINT_MASTER_TITLE,
  OFFER_MODAL_PRINT_MASTER_PER_SUBSIDIARY_LABEL,
  OFFER_MODAL_PRINT_MASTER_FILE_LABEL,
  OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_PER_SUBSIDIARY_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_LABEL,
  OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER,
  NO_SUBSIDIARIES_SELECTED,
} from '../../../constants/labels';
import {
  EXTRA_COPIES_ARRAY,
  selectPickerTheme,
} from '../../../constants/constants';

import { PrintDataProps } from '../../../@types/Modal.d';
import { MasterFile, ExtraCopy } from '../../../@types/Common.d';

const PrintData: React.FC<PrintDataProps> = (props: PrintDataProps) => {
  const {
    client,
    printPerSubsidiary,
    extraCopriesPerSubsidiary,
    masterFiles,
    selectedSubsidiaries,
    changeExtraCopies,
    changeExtraCopiesPerSubsidiary,
    changeMasterFiles,
    changePrintPerSubsidiary,
  } = props;

  const onChangePrintPerSubsidiary = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    changePrintPerSubsidiary(event.currentTarget.checked);
  };

  const onChangeExtraCopiesPerSubsidiary = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    changeExtraCopiesPerSubsidiary(event.currentTarget.checked);
  };

  const onChangeMasterFiles = (
    event: React.ChangeEvent<HTMLInputElement>,
    subsidiaryId: number
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    if (event.currentTarget.files !== null)
      changeMasterFiles({
        masterFile: event.currentTarget.files[0],
        subsidiaryId,
      } as MasterFile);
  };

  const onChangeExtraCopies = (data: any, subsidiaryId: number): void => {
    if (data && data !== null)
      changeExtraCopies({ extraCopies: data.value, subsidiaryId } as ExtraCopy);
  };

  return (
    <div>
      <ContentContainer title={OFFER_MODAL_PRINT_MASTER_TITLE}>
        {client && (
          <>
            <Form.Row>
              <Col xs={12} xl={6} className="switch-container">
                <Form.Check
                  type="switch"
                  id="print-per-subsidiary-switch"
                  label=""
                  checked={printPerSubsidiary}
                  onChange={onChangePrintPerSubsidiary}
                />
                <div className="sub-section-title">
                  {OFFER_MODAL_PRINT_MASTER_PER_SUBSIDIARY_LABEL}
                </div>
              </Col>
            </Form.Row>
            <div className="separator" />
          </>
        )}
        <Form.Row>
          <Col>
            {printPerSubsidiary ? (
              selectedSubsidiaries.length > 0 ? (
                selectedSubsidiaries.map((subsidiary) => (
                  <Row className="print-item-container">
                    <Col
                      xs={6}
                      xl={2}
                      className="custom-text-wrap subsidiary-name p-0"
                    >
                      {subsidiary.name}
                    </Col>
                    <Col xs={6} xl={5} className="p-0">
                      <Form.File
                        id={`subsidiary-${subsidiary.id}-master-file-input`}
                        data-browse={OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL}
                        accept=".pdf"
                        label={
                          masterFiles.find(
                            (file) => file.subsidiaryId === subsidiary.id
                          )?.masterFile.name ?? ''
                        }
                        custom
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => onChangeMasterFiles(event, subsidiary.id)}
                      />
                    </Col>
                  </Row>
                ))
              ) : (
                <Col className="empty-list-container">
                  {NO_SUBSIDIARIES_SELECTED}
                </Col>
              )
            ) : (
              <Row className="print-item-container">
                <Col
                  xs={6}
                  xl={2}
                  className="custom-text-wrap subsidiary-name p-0"
                >
                  {OFFER_MODAL_PRINT_MASTER_FILE_LABEL}
                </Col>
                <Col xs={6} xl={5} className="p-0">
                  <Form.File
                    id="master-file-input"
                    data-browse={OFFER_MODAL_PRINT_MASTER_BUTTON_LABEL}
                    accept=".pdf"
                    label={
                      masterFiles.find((file) => file.subsidiaryId === -1)
                        ?.masterFile.name ?? ''
                    }
                    custom
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeMasterFiles(event, -1)
                    }
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Form.Row>
      </ContentContainer>
      <ContentContainer title={OFFER_MODAL_PRINT_EXTRA_COPIES_LABEL}>
        {client && (
          <>
            <Form.Row>
              <Col xs={12} xl={6} className="switch-container">
                <Form.Check
                  type="switch"
                  id="extra-copies-per-subsidiary-switch"
                  label=""
                  checked={extraCopriesPerSubsidiary}
                  onChange={onChangeExtraCopiesPerSubsidiary}
                />
                <div className="sub-section-title">
                  {OFFER_MODAL_PRINT_EXTRA_COPIES_PER_SUBSIDIARY_LABEL}
                </div>
              </Col>
            </Form.Row>
            <div className="separator" />
          </>
        )}
        <Form.Row>
          <Col>
            {extraCopriesPerSubsidiary ? (
              selectedSubsidiaries.length > 0 ? (
                selectedSubsidiaries.map((subsidiary) => (
                  <Row className="print-item-container">
                    <Col
                      xs={6}
                      xl={2}
                      className="custom-text-wrap subsidiary-name p-0"
                    >
                      {subsidiary.name}
                    </Col>
                    <Col xs={6} xl={2} className="p-0">
                      <Select
                        // blurInputOnSelect
                        isSearchable={false}
                        options={EXTRA_COPIES_ARRAY}
                        onChange={(data) =>
                          onChangeExtraCopies(data, subsidiary.id)
                        }
                        theme={(theme: any) => selectPickerTheme(theme)}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        placeholder={
                          OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER
                        }
                      />
                    </Col>
                  </Row>
                ))
              ) : (
                <Col className="empty-list-container">
                  {NO_SUBSIDIARIES_SELECTED}
                </Col>
              )
            ) : (
              <Row className="print-item-container">
                <Col
                  xs={6}
                  xl={2}
                  className="custom-text-wrap subsidiary-name p-0"
                >
                  {OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_LABEL}
                </Col>
                <Col xs={6} xl={2} className="p-0">
                  <Select
                    // blurInputOnSelect
                    isSearchable={false}
                    options={EXTRA_COPIES_ARRAY}
                    onChange={(data) => onChangeExtraCopies(data, -1)}
                    theme={(theme: any) => selectPickerTheme(theme)}
                    styles={{
                      menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    placeholder={
                      OFFER_MODAL_PRINT_EXTRA_COPIES_AMOUNT_PLACEHOLDER
                    }
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Form.Row>
      </ContentContainer>
    </div>
  );
};

export default PrintData;
