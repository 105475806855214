import { Theme } from 'react-select';
// eslint-disable-next-line import/no-cycle
import { Product } from '../@types/Common.d';

export const COOKIE_USER_NAME = 'user';

export const selectPickerTheme = (theme: Theme): Theme =>
  ({
    borderRadius: 0,
    colors: {
      ...theme.colors,
      // danger: '#DE350B',
      // dangerLight: '#FFBDAD',
      neutral0: 'rgb(252, 252, 252)', // bg
      neutral5: 'rgb(215, 215, 215)',
      neutral10: 'rgb(215, 215, 215)',
      neutral20: 'rgb(0, 0, 0)', // border
      neutral30: 'rgb(63, 175, 212)', // hover
      // neutral40: 'rgb(83, 164, 198)', // icon hover
      neutral50: 'rgb(116, 116, 116)', // hint color
      neutral60: 'rgb(116, 116, 116)', // icon not focused
      neutral70: 'rgb(116, 116, 116)',
      neutral80: 'rgb(91, 91, 91)', // drop icon press & text
      neutral90: 'rgb(116, 116, 116)',
      primary: 'rgb(63, 175, 212)', // border focus,
      primary25: 'rgb(63, 175, 212)', // item hover
      primary50: 'rgb(83, 164, 198)', // item press
      primary75: 'rgb(215, 215, 215)',
    },
    spacing: theme.spacing,
  } as Theme);

export const TRANSMISSION_TYPE_ORDER = 'ORDER';
export const TRANSMISSION_TYPE_OFFER = 'OFFER';

export const WEEKPART_WEEKEND: string = 'WEEKEND';
export const WEEKPART_MIDWEEK: string = 'MIDWEEK';
export const WEEKPART_BEST: string = 'BEST';

export const SALUTATION_MALE = 'Herr';
export const SALUTATION_FEMALE = 'Frau';

export const SALUTATION_ARRAY = [
  { value: SALUTATION_MALE, label: SALUTATION_MALE },
  { value: SALUTATION_FEMALE, label: SALUTATION_FEMALE },
];

export const EXTRA_COPIES_ARRAY = [
  { value: 0, label: 'Keine' },
  { value: 100, label: '100 Stk.' },
  { value: 250, label: '250 Stk.' },
];

export const DEFAULT_PRODUCT_DISTRIBUTION = 'Verteilung';
export const DEFAULT_PRODUCT_PRINT_AND_DISTRIBUTION = 'Druck & Verteilung';

export const DEFAULT_PRODUCTS = [
  {
    name: DEFAULT_PRODUCT_DISTRIBUTION,
    printDocRequired: false,
    id: -1,
  } as Product,
  {
    name: DEFAULT_PRODUCT_PRINT_AND_DISTRIBUTION,
    printDocRequired: true,
    id: -2,
  } as Product,
];

export const STEP_INIT = 2;
export const STEP_SELECTION = 0;
export const STEP_LAYOUT = 1;
export const STEP_DISTRIBUTION = 2;

export const REQUEST_IDENTIFIER_GET_CLIENTS = 'GET_CLIENTS';
export const REQUEST_IDENTIFIER_GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const REQUEST_IDENTIFIER_CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const REQUEST_IDENTIFIER_LOGIN = 'LOGIN';
export const REQUEST_IDENTIFIER_SEND_OFFER_ORDER = 'SEND_OFFER_ORDER';
