import * as React from 'react';

import { Row } from 'react-bootstrap';
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import AreaListHeaderItem from './AreaListHeaderItem';

import { removeAllAreas } from '../../../util/iFrameMessageUtil';

import {
  TOOLTIP_SUBSIDIARY_COLLAPSE_ALL,
  TOOLTIP_SUBSIDIARY_EXPAND_ALL,
  TOOLTIP_SUBSIDIARY_REMOVE_ALL,
} from '../../../constants/labels';

import { AreaListHeaderProps } from '../../../@types/AreaList.d';

const AreaListHeader: React.FC<AreaListHeaderProps> = (
  props: AreaListHeaderProps
) => {
  const { collapseAll, showCollapseAll, showAll, summary } = props;

  const collapseAllItems = (): void => {
    collapseAll();
  };

  const removeAllItems = (): void => {
    removeAllAreas();
  };

  return (
    <Row className="no-gutters area-list-header">
      {showCollapseAll && (
        <AreaListHeaderItem
          icon={showAll ? faAngleDoubleDown : faAngleDoubleUp}
          tooltipLabel={
            showAll
              ? TOOLTIP_SUBSIDIARY_COLLAPSE_ALL
              : TOOLTIP_SUBSIDIARY_EXPAND_ALL
          }
          action={collapseAllItems}
          mrAuto
          position="left"
        />
      )}
      {!summary && (
        <AreaListHeaderItem
          icon={faTrash}
          tooltipLabel={TOOLTIP_SUBSIDIARY_REMOVE_ALL}
          action={removeAllItems}
          position="right"
        />
      )}
    </Row>
  );
};

export default AreaListHeader;
