import '../../styles/Header.scss';

import * as React from 'react';

import { Row } from 'react-bootstrap';

import HeaderMenu from './HeaderMenu';
import HeaderLogin from './HeaderLogin';

import { HeaderProps, HeaderState } from '../../@types/Header.d';

export default class HeaderContainer extends React.Component<
  HeaderProps,
  HeaderState
> {
  constructor(props: HeaderProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    const {
      user,
      clients,
      selectedClient,
      changeSelectedClient,
      showLoginModal,
      logoutUser,
      showChangePassword,
    } = this.props;

    return (
      <Row className="header-container">
        {/* <Col className="spacer" /> */}
        <Row className="no-gutters w-100">
          {user ? (
            <HeaderMenu
              user={user}
              clients={clients}
              changeSelectedClient={changeSelectedClient}
              selectedClient={selectedClient}
              logoutUser={logoutUser}
              showChangePassword={showChangePassword}
            />
          ) : (
            <HeaderLogin showLoginModal={showLoginModal} />
          )}
        </Row>
      </Row>
    );
  }
}
