/* eslint-disable react/jsx-wrap-multilines */
import * as React from 'react';

import { OverlayTrigger } from 'react-bootstrap';

import { TooltipProps } from '../../@types/App.d';

const CustomTooltip: React.FC<TooltipProps> = (props: TooltipProps) => {
  const { content, children, placement, tooltipId } = props;

  return (
    <OverlayTrigger
      placement={placement ?? 'auto'}
      overlay={
        <div id={tooltipId} className="custom-tooltip">
          {content}
        </div>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default CustomTooltip;
