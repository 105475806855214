/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import * as React from 'react';

import { Col, DropdownButton, Dropdown, Row, Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  HEADER_USER_MENU_OPTION_CHANGE_PASS,
  HEADER_USER_MENU_OPTION_LOGOUT,
  HEADER_CLIENT_LABEL,
  HEADER_CLIENT_PLACEHOLDER,
} from '../../constants/labels';
import { selectPickerTheme } from '../../constants/constants';

import { HeaderMenuProps, ClientSelect } from '../../@types/Header.d';

import userPlaceholder from '../../resources/img/userPlaceholder.png';

const HeaderMenu: React.FC<HeaderMenuProps> = (props: HeaderMenuProps) => {
  const {
    clients,
    selectedClient,
    changeSelectedClient,
    logoutUser,
    showChangePassword,
    user,
  } = props;

  let avatarSrc = user?.avatar;

  if (!avatarSrc || avatarSrc !== '') avatarSrc = userPlaceholder;

  const clientSelection: ClientSelect[] =
    clients?.map((client) => ({
      label: client.name,
      value: client,
    })) ?? ([] as ClientSelect[]);

  const onClickLogout = (event: any): void => {
    logoutUser();
    event.preventDefault();
  };

  const onClickChangePassword = (event: any): void => {
    showChangePassword(true);
    event.preventDefault();
  };

  const onChangeClientSelect = (selection: any): void => {
    if (selection && selection !== null) {
      changeSelectedClient(selection.value);
    }
  };

  const selectValue = clientSelection.find(
    (client) => client.value.id === selectedClient?.id
  );

  return (
    <Col className="header-menu-container">
      <Row className="user-menu-container justify-content-right">
        <Col className="user-avatar">
          <img
            src={avatarSrc}
            alt={selectedClient ? selectedClient.name : 'avatar'}
          />
        </Col>
        <Col className="user-menu">
          <DropdownButton
            id="user-menu-dropdown"
            title={`${user.prename} ${user.lastname}`}
            className="user-menu-dropdown"
            alignRight
          >
            <Dropdown.Item as="button" onClick={onClickChangePassword}>
              {HEADER_USER_MENU_OPTION_CHANGE_PASS}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as="button" onClick={onClickLogout}>
              {HEADER_USER_MENU_OPTION_LOGOUT}
            </Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col xs={3} className="client-select-label">
          {HEADER_CLIENT_LABEL}
        </Col>
        <Col className="client-select-container">
          <Form.Group>
            <Select
              blurInputOnSelect
              isSearchable
              hasVaue
              placeholder={HEADER_CLIENT_PLACEHOLDER}
              options={clientSelection}
              value={selectValue}
              onChange={onChangeClientSelect}
              theme={(theme) => selectPickerTheme(theme)}
            />
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
};

export default HeaderMenu;
