import '../../styles/DistributionPlaning.scss';

import * as React from 'react';

import { Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';

import HeaderContainer from '../Header/HeaderContainer';
import SubsidiaryAreaList from './AreaList/SubsidiaryAreaList';
import AreaList from './AreaList/AreaList';
import CirculationItem from '../Common/CirculationItem';
import AreaListHeader from './AreaList/AreaListHeader';
import PriceItem from '../Common/PriceItem';

import {
  TRANSMISSION_TYPE_ORDER,
  selectPickerTheme,
  DEFAULT_PRODUCTS,
} from '../../constants/constants';
import {
  REQUEST_BUTTON_TITLE,
  HEADER_PRODUCT_INVALID,
  HEADER_PRODUCT_PLACEHOLDER,
  HEADER_PRODUCT_LABEL,
} from '../../constants/labels';

import config from '../../config';

import {
  DistributionPlaningContainerProps,
  DistributionPlaningContainerState,
} from '../../@types/DistributionPlaning.d';

export default class DistributionPlaningContainer extends React.Component<
  DistributionPlaningContainerProps,
  DistributionPlaningContainerState
> {
  constructor(props: DistributionPlaningContainerProps) {
    super(props);

    this.state = { showAll: true };

    this.onClickOfferButton = this.onClickOfferButton.bind(this);
    this.onChangeProduct = this.onChangeProduct.bind(this);

    this.collapseAllItems = this.collapseAllItems.bind(this);
  }

  onClickOfferButton(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    const { showOrderRequestModal } = this.props;

    showOrderRequestModal(true);
    event.preventDefault();
  }

  onChangeProduct(pProduct: any): void {
    const { setSelectedProduct } = this.props;
    if (pProduct && pProduct !== null) setSelectedProduct(pProduct.value);
  }

  collapseAllItems(): void {
    const { showAll } = this.state;

    this.setState({ showAll: !showAll });
  }

  render(): JSX.Element {
    const {
      iFrameURL,
      selectedProduct,
      user,
      clients,
      selectedClient,
      changeSelectedClient,
      showLoginModal,
      showChangePassword,
      logoutUser,
      subsidiaryMode,
      weekpart,
      areas,
      selectedSubsidiaries,
      setSelectedProduct,
      totalCirculation,
      price,
    } = this.props;
    const { showAll } = this.state;

    return (
      <Row className="distribution-container">
        <Col md={12} lg={8} xl={9} className="map-container">
          <iframe
            id="map"
            title="fpm-mratz"
            className="map-iframe"
            frameBorder="0"
            allowFullScreen
            allow="geolocation"
            src={iFrameURL}
            onLoad={() => setSelectedProduct(selectedProduct)}
          />
        </Col>
        <Col md={12} lg={4} xl={3} className="area-container">
          <HeaderContainer
            user={user}
            clients={clients}
            selectedClient={selectedClient}
            changeSelectedClient={changeSelectedClient}
            showLoginModal={showLoginModal}
            showChangePassword={showChangePassword}
            logoutUser={logoutUser}
          />
          <Row className="no-gutters pt-2">
            <Col xs={3} className="client-select-label">
              {HEADER_PRODUCT_LABEL}
            </Col>
            <Col className="client-select-container">
              <Form.Group>
                {/* <Form.Label style={{ color: 'white' }}>{REQUEST_MODAL_PRODUCT_LABEL}</Form.Label> */}
                <Select
                  blurInputOnSelect
                  isSearchable={false}
                  options={
                    selectedClient?.products?.map((pProduct) => ({
                      label: pProduct.name,
                      value: pProduct,
                    })) ??
                    DEFAULT_PRODUCTS.map((pProduct) => ({
                      label: pProduct.name,
                      value: pProduct,
                    }))
                  }
                  placeholder={HEADER_PRODUCT_PLACEHOLDER}
                  onChange={this.onChangeProduct}
                  theme={(theme: any) => selectPickerTheme(theme)}
                  value={
                    selectedProduct
                      ? { label: selectedProduct.name, value: selectedProduct }
                      : null
                  }
                />
                <Form.Control
                  value={selectedProduct?.name ?? ''}
                  onChange={() => {}}
                  hidden
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {HEADER_PRODUCT_INVALID}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="area-list-container">
            <Col md={12} className="h-100 p-0">
              <AreaListHeader
                showCollapseAll={subsidiaryMode}
                collapseAll={this.collapseAllItems}
                showAll={showAll}
              />
              <Row className="no-gutters">
                <Col className="p-0">
                  {subsidiaryMode ? (
                    <SubsidiaryAreaList
                      subsidiaries={selectedSubsidiaries}
                      weekpart={weekpart}
                      showAll={showAll}
                    />
                  ) : (
                    <AreaList show areas={areas} weekpart={weekpart} />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          {user && (selectedClient?.showPrice || config.showPrice) && (
            <PriceItem price={price} />
          )}
          <CirculationItem
            circulation={totalCirculation}
            weekpart={weekpart}
            totalCirculation
          />
          <Row className="no-gutters pt-2">
            <Button
              className="ci-button offer-button"
              onClick={this.onClickOfferButton}
            >
              {REQUEST_BUTTON_TITLE(
                selectedClient !== undefined &&
                  selectedClient.transmissionType === TRANSMISSION_TYPE_ORDER
              )}
            </Button>
          </Row>
        </Col>
      </Row>
    );
  }
}
