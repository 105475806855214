export const FRA_API_KEY =
  'fmf2LsjNm5uKpXpDsqJgcCDri5UKcrgEEi1DNRHJeIhPeEej3CzldrDHR0DEAJtd';

export const SERVICE_AREA_DATA = 'areaDataService/';
export const PATH_AREAS = `${SERVICE_AREA_DATA}areas`;

export const SERVICE_SECURITY = 'securityService/';
export const PATH_LOGIN = `${SERVICE_SECURITY}login`;
export const PATH_CHANGE_PASSWORD = `${SERVICE_SECURITY}changePassword`;
export const PATH_USERCLIENTS = `${SERVICE_SECURITY}userclients`;

export const SERVICE_OFFER = 'offerService/';
export const PATH_OFFER_REQUEST = `${SERVICE_OFFER}offerRequest`;

export const SERVICE_ORDER = 'orderService/';
export const PATH_ORDER_REQUEST = `${SERVICE_ORDER}order`;

export const SERVICE_GEO_DATA = 'geoDataService/';
export const PATH_CLIENT_DATA = (clientId: number): string =>
  `${SERVICE_GEO_DATA}client/${clientId}`;

export const PARAM_WEEKPART = 'WEEKPART';
export const PARAM_CLIENT_ID = 'CLIENTID';
export const PARAM_PARENTORIGIN = 'PARENTORIGIN';
export const PARAM_SUBSIDIARYMODE = 'SUBSIDIARYMODE';
export const PARAM_EMAIL = 'EMAIL';
export const PARAM_AREAKEYS = 'AREAKEYS';
export const PARAM_DISTRIBUTION_WEEK = 'DISTRIBUTIONWEEK';
export const PARAM_DISTRIBUTION_YEAR = 'DISTRIBUTIONYEAR';

export const SERVICE_CALCULATION = 'calculationService/';
export const PATH_CALCULATION_FPP = `${SERVICE_CALCULATION}calculationFpp`;
