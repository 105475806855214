import * as React from 'react';
import { Form, Col } from 'react-bootstrap';
import { SendProps } from '../../../@types/Modal.d';
import ContentContainer from './ContentContainer';
import {
  OFFER_MODAL_SEND_MESSAGE_TITLE,
  OFFER_MODAL_SEND_MESSAGE_LABEL,
  OFFER_MODAL_SEND_PRIVACY_TITLE,
  OFFER_MODAL_SEND_PRIVACY_LABEL,
  OFFER_MODAL_SEND_PRIVACY_INFO,
  OFFER_MODAL_SEND_PRIVACY_INVALID,
} from '../../../constants/labels';

const Send: React.FC<SendProps> = (props: SendProps) => {
  const { message, privacyCheck, changeMessage, changePrivacyCheck } = props;

  const onChangeMessage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    changeMessage(event.currentTarget.value);
  };

  const onChangePrivacyCheck = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    changePrivacyCheck(event.currentTarget.checked);
  };

  return (
    <>
      <ContentContainer title={OFFER_MODAL_SEND_MESSAGE_TITLE}>
        <Form.Row className="d-flex">
          <Col xl={2}>
            <Form.Label className="pr-4">
              {OFFER_MODAL_SEND_MESSAGE_LABEL}
            </Form.Label>
          </Col>
          <Col>
            <Form.Control
              value={message}
              onChange={onChangeMessage}
              as="textarea"
            />
          </Col>
        </Form.Row>
      </ContentContainer>
      <ContentContainer title={OFFER_MODAL_SEND_PRIVACY_TITLE}>
        <div className="custom-switch-container">
          <Form.Check
            type="checkbox"
            id="privacy-check"
            custom
            label={OFFER_MODAL_SEND_PRIVACY_LABEL}
            onChange={onChangePrivacyCheck}
            checked={privacyCheck}
            isInvalid={!privacyCheck}
            feedback={OFFER_MODAL_SEND_PRIVACY_INVALID}
            required
          />
          <div className="custom-switch-info">
            <small>{OFFER_MODAL_SEND_PRIVACY_INFO}</small>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default Send;
