import * as React from 'react';

import { Modal, Button, Col, Form } from 'react-bootstrap';
import md5 from 'md5';

import {
  BUTTON_TITLE_SUBMIT,
  BUTTON_TITLE_ABORT,
  CHANGE_PASSWORD_MODAL_TITLE,
  CHANGE_PASSWORD_MODAL_OLD_PASS_LABEL,
  CHANGE_PASSWORD_MODAL_OLD_PASS_PLACEHOLDER,
  CHANGE_PASSWORD_MODAL_OLD_PASS_INVALID,
  CHANGE_PASSWORD_MODAL_NEW_PASS_LABEL,
  CHANGE_PASSWORD_MODAL_NEW_PASS_PLACEHOLDER,
  CHANGE_PASSWORD_MODAL_NEW_PASS_INVALID,
  CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_LABEL,
  CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_PLACEHOLDER,
  CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_INVALID,
  CHANGE_PASSWORD_MODAL_FAILURE,
} from '../../constants/labels';

import {
  ChangePasswordModalProps,
  ChangePasswordModalState,
} from '../../@types/Modal.d';

export default class ChangePasswordModal extends React.Component<
  ChangePasswordModalProps,
  ChangePasswordModalState
> {
  constructor(props: ChangePasswordModalProps) {
    super(props);

    this.state = {
      formValidated: false,
      changePasswordFailed: false,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };

    this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
    this.onChangeNewPassword = this.onChangeNewPassword.bind(this);
    this.onChangeConfirmNewPassword = this.onChangeConfirmNewPassword.bind(
      this
    );

    this.changePasswordCallback = this.changePasswordCallback.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangeOldPassword(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ oldPassword: md5(event.currentTarget.value || '') });
  }

  onChangeNewPassword(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ newPassword: md5(event.currentTarget.value || '') });
  }

  onChangeConfirmNewPassword(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ confirmNewPassword: md5(event.currentTarget.value || '') });
  }

  onSubmit(event: React.FormEvent<any>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ formValidated: true }, () => {
      const { submitNewPassword } = this.props;
      const { oldPassword, newPassword, confirmNewPassword } = this.state;
      if (formValid && newPassword === confirmNewPassword) {
        submitNewPassword(
          oldPassword,
          newPassword,
          this.changePasswordCallback
        );
      }
    });
  }

  onHide(): void {
    const { showModal } = this.props;

    showModal(false);
    this.setState({
      formValidated: false,
      changePasswordFailed: false,
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
  }

  changePasswordCallback(status: number): void {
    this.setState({ changePasswordFailed: status === 401 });
    if (status < 300) this.onHide();
  }

  render(): JSX.Element {
    const { show } = this.props;
    const {
      formValidated,
      newPassword,
      confirmNewPassword,
      changePasswordFailed,
    } = this.state;

    return (
      <Modal show={show} onHide={this.onHide} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{CHANGE_PASSWORD_MODAL_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="change-password-form"
            onSubmit={this.onSubmit}
            validated={formValidated}
            noValidate
          >
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {CHANGE_PASSWORD_MODAL_OLD_PASS_LABEL}
                  </Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="current-password"
                    type="password"
                    onChange={this.onChangeOldPassword}
                    placeholder={CHANGE_PASSWORD_MODAL_OLD_PASS_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {CHANGE_PASSWORD_MODAL_OLD_PASS_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {CHANGE_PASSWORD_MODAL_NEW_PASS_LABEL}
                  </Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="off"
                    type="password"
                    onChange={this.onChangeNewPassword}
                    placeholder={CHANGE_PASSWORD_MODAL_NEW_PASS_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {CHANGE_PASSWORD_MODAL_NEW_PASS_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    {CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_LABEL}
                  </Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="off"
                    type="password"
                    onChange={this.onChangeConfirmNewPassword}
                    placeholder={
                      CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_PLACEHOLDER
                    }
                    required
                    isInvalid={newPassword !== confirmNewPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {CHANGE_PASSWORD_MODAL_CONFIRM_NEW_PASS_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
          {changePasswordFailed && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {CHANGE_PASSWORD_MODAL_FAILURE}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              form="change-password-form"
              type="submit"
            >
              {BUTTON_TITLE_SUBMIT}
            </Button>
          </Col>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              onClick={this.onHide}
            >
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
