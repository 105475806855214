import * as React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { MessageProps, MessageState } from '../../@types/App.d';
import { WarningMessageType } from '../../@types/Common.d';

export default class Message extends React.Component<
  MessageProps,
  MessageState
> {
  constructor(props: MessageProps) {
    super(props);

    this.state = {};

    this.onClickClose = this.onClickClose.bind(this);
    this.onKeyUpClose = this.onKeyUpClose.bind(this);
    this.onHide = this.onHide.bind(this);
    this.setContents = this.setContents.bind(this);
  }

  onClickClose(event: React.MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.onHide();
  }

  onKeyUpClose(event: React.KeyboardEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.onHide();
  }

  onHide(): void {
    const { closeMessage } = this.props;

    this.setState({
      content: undefined,
      title: undefined,
      type: undefined,
    });

    closeMessage(false);
  }

  setContents(
    content?: string,
    title?: string,
    type?: WarningMessageType
  ): void {
    this.setState({ content, title, type });
  }

  render(): JSX.Element {
    const { content, title, type } = this.state;
    const { show } = this.props;

    return (
      <div
        className={`warning-message-container ${
          show ? 'show ' : 'hidden '
        }${type}`}
      >
        <div
          className="warning-message-icon hover-icon"
          role="button"
          tabIndex={0}
          onClick={this.onClickClose}
          onKeyUp={this.onKeyUpClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className="warning-message-title">{title}</div>
        <div className="warning-message-content">{content}</div>
      </div>
    );
  }
}
