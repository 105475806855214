import '../../../styles/AreaList.scss';

import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

// eslint-disable-next-line import/no-cycle
import AreaListItem from './AreaListItem';

import { sortAreas } from '../../../util/sortUtil';

import { AreaListPorps } from '../../../@types/AreaList.d';

const AreaList: React.FC<AreaListPorps> = (props: AreaListPorps) => {
  const { areas, weekpart, show, summary } = props;

  const getMaxHeight = (): number =>
    areas.reduce((acc, area) => {
      let rAcc = acc;
      rAcc += 54 + 40 * area.additionalAreas.length;
      return rAcc;
    }, 0);

  return (
    <ListGroup
      className={`area-list collapse-content ${
        show ? 'shown' : 'hidden'
      } height`}
      style={{ maxHeight: getMaxHeight() }}
    >
      {sortAreas(areas).map((area) => (
        <AreaListItem
          key={area.areaKey}
          additionalArea={false}
          area={area}
          weekpart={weekpart}
          summary={summary}
        />
      ))}
    </ListGroup>
  );
};

export default AreaList;
