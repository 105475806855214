import '../../styles/StepWizard.scss';

import * as React from 'react';

import { Button, Row, Col } from 'react-bootstrap';

import {
  BUTTON_TITLE_ABORT,
  BUTTON_TITLE_NEXT,
  BUTTON_TITLE_PREVOUS,
  BUTTON_TITLE_SUBMIT,
} from '../../constants/labels';

import {
  StepWizardProps,
  StepWizardState,
  TitleBarProps,
} from '../../@types/App.d';

const TitleBar: React.FC<TitleBarProps> = (props: TitleBarProps) => {
  const { titles, activeIndex } = props;

  return (
    <Row className="title-bar">
      {titles.map((title, index) => (
        <Col
          // eslint-disable-next-line react/no-array-index-key
          key={`${title}-${index}`}
          className={`title-bar-item ${index === activeIndex ? 'active' : ''}`}
        >
          {title}
        </Col>
      ))}
    </Row>
  );
};

export default class StepWizard extends React.Component<
  StepWizardProps,
  StepWizardState
> {
  constructor(props: StepWizardProps) {
    super(props);

    const { titles, children } = this.props;

    this.state = {
      step: 0,
      direction: 'next',
      titles: titles.filter((title, index) => children[index]),
    };

    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.abort = this.abort.bind(this);

    this.getCSSAnimationClass = this.getCSSAnimationClass.bind(this);
  }

  getCSSAnimationClass(index: number): string {
    const { step, direction } = this.state;

    if (index < step) return 'hidden-to-left';
    if (index > step) return 'hidden-to-right';

    if (direction === 'previous') return 'show-from-left';

    return 'show-from-right';
  }

  nextStep(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();

    const { children, setFormValidated } = this.props;
    const { step } = this.state;

    if (step + 1 < children.filter(child => child).length)
      this.setState({ step: step + 1, direction: 'next' }, () => {
        if (
          step + 1 === children.filter(child => child).length - 1 &&
          setFormValidated
        )
          setFormValidated();
      });
  }

  previousStep(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();

    const { step } = this.state;

    if (step - 1 >= 0) this.setState({ step: step - 1, direction: 'previous' });
  }

  abort(event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.preventDefault();
    event.stopPropagation();

    const { abort } = this.props;

    abort();
  }

  render(): JSX.Element {
    const { children, formId, showTitleBar, checkFormValid } = this.props;
    const { step, titles } = this.state;

    const lastStep = step === children.filter(child => child).length - 1;

    return (
      <div className="wizard-container">
        {showTitleBar ? (
          <TitleBar titles={titles} activeIndex={step} />
        ) : (
          <div className="wizard-title">{titles[step]}</div>
        )}
        <div className="wizard-content">
          {children
            .filter(child => child)
            .map((child, index) =>
              React.Children.map(child, pChild => (
                <div
                  className={`wizard-content-item-wrapper ${this.getCSSAnimationClass(
                    index
                  )}`}
                >
                  <div className="wizard-content-item">{pChild}</div>
                </div>
              ))
            )}
        </div>
        <div className="wizard-buttons">
          <div className="wizard-button previous">
            <Button
              className="ci-button"
              onClick={this.previousStep}
              disabled={step === 0}
            >
              {BUTTON_TITLE_PREVOUS}
            </Button>
          </div>
          <div className="wizard-button abort">
            <Button className="ci-button" onClick={this.abort}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </div>
          <div className="wizard-button next">
            {lastStep ? (
              <Button
                className="ci-button"
                type="submit"
                form={formId}
                disabled={checkFormValid ? !checkFormValid() : false}
              >
                {BUTTON_TITLE_SUBMIT}
              </Button>
            ) : (
              <Button className="ci-button" onClick={this.nextStep}>
                {BUTTON_TITLE_NEXT}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
