import * as React from 'react';

import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../../Common/CustomTooltip';

import { AreaListHeaderItemProps } from '../../../@types/AreaList.d';

const AreaListHeaderItem: React.FC<AreaListHeaderItemProps> = (
  props: AreaListHeaderItemProps
) => {
  const { icon, tooltipLabel, action, mrAuto, position } = props;

  const onClick = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    action();
  };

  return (
    <Col
      xs={2}
      md={1}
      className={`${mrAuto ? 'mr-auto' : ''} item ${position}`}
    >
      <CustomTooltip tooltipId={`icon-${tooltipLabel}`} content={tooltipLabel}>
        <FontAwesomeIcon className="hover-icon" onClick={onClick} icon={icon} />
      </CustomTooltip>
    </Col>
  );
};

export default AreaListHeaderItem;
