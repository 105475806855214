import * as React from 'react';

import { Col, Modal, Button, Form } from 'react-bootstrap';
import md5 from 'md5';

import {
  LOGIN_MODAL_TITLE,
  LOGIN_MODAL_LOGIN_BUTTON,
  LOGIN_MODAL_ABORT_BUTTON,
  LOGIN_MODAL_EMAIL_TITLE,
  LOGIN_MODAL_EMAIL_PLACEHOLDER,
  LOGIN_MODAL_EMAIL_INVALID,
  LOGIN_MODAL_PASSWORD_TITLE,
  LOGIN_MODAL_PASSWORD_PLACEHOLDER,
  LOGIN_MODAL_PASSWORD_INVALID,
  LOGIN_MODAL_ABORT_FAILURE,
} from '../../constants/labels';

import { LoginModalProps, LoginModalState } from '../../@types/Modal.d';

export default class LoginModal extends React.Component<
  LoginModalProps,
  LoginModalState
> {
  constructor(props: LoginModalProps) {
    super(props);

    this.state = {
      formValidated: false,
      loginFailure: false,
      email: '',
      password: '',
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
    this.loginCallback = this.loginCallback.bind(this);
  }

  onChangeEmail(event: React.ChangeEvent<HTMLInputElement>): void {
    const email = event.currentTarget.value || '';

    this.setState({ email });
  }

  onChangePassword(event: React.ChangeEvent<HTMLInputElement>): void {
    const password = md5(event.currentTarget.value || '');

    this.setState({ password });
  }

  onSubmit(event: React.FormEvent<any>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ formValidated: true }, () => {
      if (formValid) {
        const { email, password } = this.state;
        const { submitLogin } = this.props;

        submitLogin(email, password, this.loginCallback);
      }
    });
  }

  onHide(): void {
    const { showModal } = this.props;

    showModal(false);
    this.setState({
      formValidated: false,
      loginFailure: false,
      email: '',
      password: '',
    });
  }

  loginCallback(status: number): void {
    const loginFailure = status > 299;

    this.setState({ loginFailure });

    if (!loginFailure) this.onHide();
  }

  render(): JSX.Element {
    const { show } = this.props;
    const { formValidated, loginFailure } = this.state;

    return (
      <Modal show={show} onHide={this.onHide} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{LOGIN_MODAL_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            id="login-modal-form"
            onSubmit={this.onSubmit}
            noValidate
            validated={formValidated}
          >
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>{LOGIN_MODAL_EMAIL_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="email"
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    onChange={this.onChangeEmail}
                    placeholder={LOGIN_MODAL_EMAIL_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {LOGIN_MODAL_EMAIL_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>{LOGIN_MODAL_PASSWORD_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="current-password"
                    type="password"
                    onChange={this.onChangePassword}
                    placeholder={LOGIN_MODAL_PASSWORD_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {LOGIN_MODAL_PASSWORD_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
          {loginFailure && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {LOGIN_MODAL_ABORT_FAILURE}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              form="login-modal-form"
              type="submit"
            >
              {LOGIN_MODAL_LOGIN_BUTTON}
            </Button>
          </Col>
          <Col className="p-0">
            <Button
              className="ci-button modal-footer-button"
              onClick={this.onHide}
            >
              {LOGIN_MODAL_ABORT_BUTTON}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
