import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import { faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../../Common/CustomTooltip';

import {
  showLocalitiesModal,
  removeArea,
} from '../../../util/iFrameMessageUtil';

import {
  TOOLTIP_AREA_SHOW_LOCALITIES,
  TOOLTIP_AREA_REMOVE,
} from '../../../constants/labels';

import { AreaIconProps } from '../../../@types/AreaList.d';

const AreaIconContainer: React.FC<AreaIconProps> = (props: AreaIconProps) => {
  const { additionalArea, area, summary } = props;

  const onClickRemove = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    removeArea(area);
  };

  const onClickLocalities = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showLocalitiesModal(area);
  };

  return (
    <Row className="no-gutters justify-content-end">
      {!summary && (
        <Col sm={summary ? 12 : 6}>
          <CustomTooltip
            tooltipId={`area-${area.id}-loc`}
            content={TOOLTIP_AREA_SHOW_LOCALITIES}
          >
            <FontAwesomeIcon
              className="icon-localities hover-icon tooltip-icon"
              onClick={onClickLocalities}
              icon={faInfoCircle}
            />
          </CustomTooltip>
        </Col>
      )}
      {!summary && (
        <Col sm={6}>
          {!additionalArea && (
            <CustomTooltip
              tooltipId={`area-${area.id}-rem`}
              content={TOOLTIP_AREA_REMOVE}
            >
              <FontAwesomeIcon
                className="icon-remove hover-icon"
                onClick={onClickRemove}
                icon={faTrash}
              />
            </CustomTooltip>
          )}
        </Col>
      )}
    </Row>
  );
};

export default AreaIconContainer;
