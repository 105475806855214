import { Area, Locality } from '../@types/Area.d';
import { ClientLocation } from '../@types/Common.d';

export const getAreaCirculation = (area: Area): number =>
  area.localities.reduce((locAcc: number, locality: Locality) => {
    let rLocAcc = locAcc;

    if (locality.selected && !Number.isNaN(+locality.circulation as number)) {
      rLocAcc += locality.circulation;
    }
    return rLocAcc;
  }, 0);

export const getTotalCirculationAreas = (areas?: Area[]): number =>
  areas?.reduce((acc, area) => {
    let rAcc = acc;

    if (!Number.isNaN(+area.circulation)) {
      rAcc += getAreaCirculation(area);
    }

    rAcc += area.additionalAreas.reduce(
      (accc: number, additionalArea: Area) => {
        let rAccc = accc;
        rAccc += getAreaCirculation(additionalArea);
        return rAccc;
      },
      0
    );
    return rAcc;
  }, 0) ?? 0;

export const getTotalCirculationSubsidiaries = (
  selectedSubsidiaries?: ClientLocation[]
): number =>
  selectedSubsidiaries?.reduce((acc: number, subsidiary: ClientLocation) => {
    let rAcc = acc;

    rAcc += getTotalCirculationAreas(subsidiary.areas);

    return rAcc;
  }, 0) ?? 0;

export const getAllSubsidiaryAreas = (
  subsidiaries?: ClientLocation[]
): Area[] =>
  subsidiaries?.reduce((acc, subsidiary) => {
    const rAcc = acc;

    return [...rAcc, ...subsidiary.areas];
  }, [] as Area[]) ?? ([] as Area[]);
