const config = {
  subsidiaryMode: process.env.REACT_APP_SUBSIDIARY_MODE === 'true',
  novadex: process.env.REACT_APP_NOVADEX === 'true',
  apiUrl: process.env.REACT_APP_API_URL,
  mapUrl: process.env.REACT_APP_MAP_URL,
  printToConsole: process.env.REACT_APP_PRINT_TO_CONSOLE === 'true',
  defaultWeekpart: process.env.REACT_APP_DEFAULT_WEEKPART,
  showPrice: process.env.REACT_APP_SHOW_PRICE === 'true',
};
export default config;
